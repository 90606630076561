<template>
  <div
    class="banner-slide"
    :style="[
      { '--title-color': slide.colorTitle ?? 'white' },
      { '--description-color': slide.colorText ?? 'white' },
      { '--button-color': slide.colotBtn ?? 'white' },
      {
        '--button-background':
          slide.colorBtnBack ?? 'var(--color-primary-base)',
      },
    ]"
  >
    <div class="banner-slide__wrp">
      <div class="banner-slide__content">
        <div class="banner-slide__block">
          <NuxtLink
            :to="`${language.urlPrefix}/${getLocalizedName(slide.url)}`"
            class="banner-slide__title"
          >
            {{ getLocalizedName(slide.title) }}
          </NuxtLink>
          <div class="banner-slide__img-wrp-mobile">
            <img
              :src="useNormalizeCtfUrl(slide.imgMain.file.url)"
              :alt="getLocalizedName(slide.title)"
              :title="getLocalizedName(slide.title)"
            />
          </div>

          <p class="banner-slide__description">
            {{ getLocalizedName(slide.description) }}
          </p>

          <NuxtLink
            class="banner-slide__link"
            :to="`${language.urlPrefix}/${getLocalizedName(slide.url)}`"
          >
            <span>{{ getLocalizedName(slide.btn) }}</span>
          </NuxtLink>
        </div>

        <div class="banner-slide__img-wrp">
          <span class="banner-slide__img-border"></span>
          <img
            :src="useNormalizeCtfUrl(slide.imgMain.file.url)"
            :alt="getLocalizedName(slide.title)"
            :title="getLocalizedName(slide.title)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { getLocalizedName } from "~/utils/localizedValues";
import { useNormalizeCtfUrl } from "~/utils/normalize";

const languageStore = useLanguageFilterStore();
const { getUserLanguage: language } = storeToRefs(languageStore);

const props = defineProps({
  slide: { type: Object, required: true },
});
</script>

<style lang="scss" scoped>
.banner-slide {
  --title-color: white;
  --description-color: white;
  --button-color: white;
  --button-background: var(--color-primary-base);

  min-height: 432px;

  @include flex-container(column, center, center);

  &__wrp {
    width: 100%;

    padding: 16px 80px;

    @include bigMobile {
      padding: 16px;
    }
  }

  &__content {
    max-width: 1100px;
    width: 100%;

    @include flex-container(row, space-between, center);

    margin: 0 auto;
    gap: 16px;

    @include bigMobile {
      justify-content: center;
    }
  }

  &__block {
    max-width: 602px;
    width: 100%;

    @include flex-container(column, flex-start, flex-start);

    position: relative;

    gap: 16px;

    @include bigMobile {
      @include flex-container(column, center, center);
    }
  }

  &__label {
    position: absolute;
    top: -65%;
    left: -15%;
  }

  &__title {
    @include font(36, 42, 700);
    color: var(--title-color);

    @include bigMobile {
      max-width: 90%;

      @include font(18, 22, 700);
      text-align: center;
    }
  }

  &__description {
    @include font(16, 22);
    color: var(--description-color);

    @include bigMobile {
      @include font(14, 20);
      width: 90%;
      text-align: center;
    }

    @include mobile {
      @include font(12, 18);
    }
  }

  &__img-wrp-mobile {
    width: 220px;
    height: 220px;

    display: none;

    @include bigMobile {
      display: block;
    }
  }

  &__img-wrp {
    flex: 0 0 350px;
    height: 350px;

    position: relative;

    @include flex-container(column, center, center);

    @include bigMobile {
      display: none;
    }

    &::before,
    &::after {
      content: "";

      width: 36px;
      height: 36px;

      display: block;

      position: absolute;

      z-index: 0;
    }

    &::after {
      border-bottom: 1px solid white;
      border-left: 1px solid white;

      bottom: 0;
      left: 0;
    }

    &::before {
      border-top: 1px solid white;
      border-right: 1px solid white;

      top: 0;
      right: 0;
    }
  }

  &__img-border {
    @include bigMobile {
      display: none;
    }

    &::before,
    &::after {
      content: "";

      width: 64px;
      height: 64px;

      display: block;

      position: absolute;

      z-index: 0;
    }

    &::after {
      border-top: 1px solid white;
      border-left: 1px solid white;

      top: 0;
      left: 0;
    }

    &::before {
      border-bottom: 1px solid white;
      border-right: 1px solid white;

      bottom: 0;
      right: 0;
    }
  }

  &__link {
    max-width: 236px;
    width: 100%;

    @include flex-container(row, center, center);

    position: relative;

    background-color: var(--button-background);

    border-radius: 30px;

    padding: 8px 16px;

    span {
      @include font(18, 24);
      color: var(--button-color);

      @include smallScreen {
        @include font(16, 24);
      }

      @include mobile {
        @include font(14, 20);
      }
    }

    @include bigMobile {
      align-self: flex-start;

      margin: 0 auto;
    }
  }
}
</style>
