<template>
  <div class="product-reviews">
    <div class="product-reviews__w">
      <ReviewsPageHeader :product="product" />
      <template v-if="reviews.data?.length > 0">
        <Reviews :reviews="reviews.data" />
        <LoadMore
          v-if="reviews.data.length < reviews.meta.pagination.total"
          class="product-reviews__button"
          @click.prevent="onShowMore"
        />
      </template>
    </div>
    <AsideCard :product="product" :navigation-height="navigationHeight" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import AsideCard from "~/modules/product/ui/components/AsideCard.vue";
import ReviewsPageHeader from "~/modules/product/ui/components/ReviewsPageHeader.vue";
import Reviews from "~/modules/product/ui/components/Reviews.vue";
import LoadMore from "~/modules/shared/pagination/LoadMore.vue";
import { fetchReviews } from "~/utils/API";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

const patternSeo = {
  metaTitle: {
    postfix: () => _T("@customer reviews"),
  },
  metaDescription: {
    prefix: () => _T("@Read product reviews"),
    postfix: () => _T("@online store") + " | GreenVision",
  },
  metaKeywords: {
    postfix: () => _T("@customer reviews"),
  },
};

configureProductSeo(props.product, patternSeo);

const reviews = ref(null);
const currentPage = ref(1);

const { data } = await useAsyncData("fetchReviews", async () => {
  return await fetchReviews(props.product.code, 1, 25).catch(() => {
    return {
      data: [],
    };
  });
});

reviews.value = data.value;

const onShowMore = async () => {
  currentPage.value += 1;

  await fetchReviews(props.product.code, currentPage.value, 25).then((data) => {
    reviews.value.data.push(...data.data);
  });
};
</script>

<style scoped lang="scss">
.product-reviews {
  @include flex-container(row, center, flex-start);
  gap: 44px;

  @include bigMobile {
    gap: 16px;
  }

  &__w {
    width: 100%;
    max-width: 880px;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__button {
    align-self: center;
  }
}
</style>
