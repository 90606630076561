import { useAPI } from "~/uses/useMyFetch";
import { cleanUrlName } from "~/utils/strings.utils";
import { useCategoriesStore } from "~/store/categoriesStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  //Redirect настройки
  //
  if (import.meta.server) {
    const config = useRuntimeConfig();

    const redirectPath = await useAPI(
      config.public.apiBaseUrl + "/user/shared/redirect",
      {
        params: {
          path: to.path,
        },
      },
    );

    if (redirectPath.data !== null) {
      return navigateTo(redirectPath.data?.path);
    }
  }

  //Выброс ошибок на несуществующие пути
  //
  const error = useError();

  if (import.meta.client && error.value && to.fullPath !== from.fullPath) {
    window.location.href = to.fullPath;
  }

  if (cleanUrlName(to.name) === "category") {
    const categoryStore = useCategoriesStore();
    const category = categoryStore.getCategoryBySlug(to.params.categorySlug);

    if (undefined === category) {
      throw createError({ statusCode: 404, fatal: true });
    }
  }

  if (cleanUrlName(to.name) === "search" && !to.query.searchQuery) {
    throw createError({ statusCode: 404, fatal: true });
  }
});
